@import "../../../styles/variables.module";
.assignment {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  @include large_device {
    padding-top: 80px;
  }
  &::before {
    content: "";
    background-image: url("../../" + $bg-base-path + "/type-of-assignment-bottom-wave.svg");
    width: 350px;
    height: 150px;
    display: inline-block;
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    z-index: -1;
    top: 40px;
    background-position: right;
  }
  &::after {
    content: "";
    background-image: url("../../" + $bg-base-path + "/assignmentbottomline.svg");
    width: 200px;
    height: 150px;
    display: inline-block;
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    z-index: -1;
    bottom: 90px;
  }
  &::before,
  &::after {
    @include large_device {
      content: none;
    }
  }
  .assignmentDetails {
    position: relative;
    margin-top: 80px;
    &::before {
      content: "";
      background-image: url("../../" + $bg-base-path + "/tab-bg.svg");
      width: 55%;
      height: 340px;
      display: inline-block;
      position: absolute;
      left: -130px;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
      top: -40px;
      @include large_device {
        width: 100%;
        top: -30px;
        height: 300px;
        left: 0;
      }
      @include mobile_device {
        height: 460px;
      }
    }
  }

  .desc {
    margin-bottom: 70px;
    @include large_device {
      margin-bottom: 60px;
    }
  }
  .services {
    position: relative;
    border-bottom: none;
    @include large_device {
      justify-content: center !important;
      width: 100%;
      margin: 0 auto;
    }
    @include mobile_device {
      margin-bottom: 20px;
    }
    &:after {
      content: "";
      position: absolute;
      background-image: url("../../" + $bg-base-path + "/dots-3.svg");
      width: 190px;
      height: 90px;
      left: -10px;
      bottom: 74px;
      background-repeat: no-repeat;
      z-index: -1;
      @include extra_large {
        bottom: 0;
      }
      @include large_device {
        content: none;
      }
    }
    &::before {
      @include extra_large {
        left: -80%;
        height: 280px;
      }
    }
    .servicesItem {
      margin: 0 15px 30px;
      @include mobile_device {
        width: 100%;
        margin: 0 0 10px 0;
      }
      &:last-child {
        .servicesTab {
          justify-content: center;
          img {
            object-fit: contain;
          }
        }
      }
    }
    .servicesTab {
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;
      background: $white-color;
      width: 200px;
      height: 180px;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 0.5rem 1rem;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      @include up_large {
        width: 160px;
        height: 160px;
      }
      @include extra_large {
        width: 140px;
        height: 120px;
      }
      @include large_device {
        width: 210px;
        height: 55px;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }
      @include mobile_device {
        width: 100%;
        height: 50px;
        justify-content: flex-start;
      }
      &.active,
      &:hover {
        border-color: $secondary-color;
        -webkit-box-shadow: 0 7px 20px 0 rgba(38, 56, 77, 0.06);
        box-shadow: 0 7px 20px 0 rgba(38, 56, 77, 0.06);
        background-color: $white-color;
      }
      span {
        &:first-child {
          @include extra_large {
            width: 40px !important;
          }
          @include large_device {
            width: 30px !important;
          }
        }
        &:last-child {
          display: block;
          color: $theme-custom-color;
          font-weight: 700;
          font-size: 20px;
          line-height: normal;
          white-space: nowrap;
          width: 100%;
          text-align: center;
          @include up_large {
            font-size: 18px;
          }
          @include large_device {
            font-size: 16px;
            text-align: left;
            margin-left: 14px;
            margin-top: 0;
            width: auto;
          }
        }
      }
    }
  }
  .tabContent {
    .tabPaneBox {
      background: $white-color;
      padding: 30px 46px;
      border-radius: 10px;
      width: 350px;
      margin: 0 auto;
      position: relative;
      @include large_device {
        padding: 25px 25px 30px 60px;
        width: 90%;
      }
      @include mobile_device {
        width: 100%;
        padding-left: 30px;
      }
      &.tabPaneMore {
        background-color: $theme-color;
        text-align: center;
        min-height: 309px;
        flex-direction: column;
        justify-content: center;
        &[class*="show"] {
          display: flex;
        }
        .exploreAllSample {
          font-weight: 700;
          font-size: 40px;
          line-height: 58px;
          color: $white-color;
          margin-bottom: 30px;
          @include mobile_device {
            font-size: 32px;
            line-height: 40px;
          }
          span {
            color: $secondary-color ;
          }
        }
      }
      h4 {
        &:before {
          @include large_device {
            left: -60px;
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        height: 3px;
        width: 30px;
        background-color: $secondary-color;
        left: 0;
        top: 45px;
        @include mobile_device {
          width: 20px;
          top: 40px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        background-image: url("../../" + $bg-base-path + "/dots-2.svg");
        width: 170px;
        height: 80px;
        left: -60px;
        top: -35px;
        background-repeat: no-repeat;
        z-index: -3;
        @include mobile_device {
          content: none;
        }
      }
      .title {
        font-size: 24px;
        font-weight: 700;
        color: $theme-custom-color;
        margin-bottom: 16px;
        line-height: 28px;
      }
      p {
        line-height: 28px;
        margin-bottom: 20px;
      }
 
    }
  }
}
